import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function useLang() {
  const { lang } = useParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (['en', 'fr'].includes(lang)) i18n.changeLanguage(lang);
    else i18n.changeLanguage('en');
  }, [lang]);
}

export default useLang;
