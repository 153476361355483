import React from 'react';
import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" style={{ padding: '1em 0', width: '100%', color: '#FFFFFF' }}>
      <h1 style={{ textAlign: 'center' }}>Oops!</h1>
      <p style={{ textAlign: 'center' }}>Sorry, an unexpected error has occurred.</p>
      {error && (
        <p style={{ textAlign: 'center' }}>
          <i>{error.statusText || error.message}</i>
        </p>
      )}
      <div style={{ textAlign: 'center' }}>
        <a href="/en" style={{ fontWeight: '800', color: '#FFFFFF' }}>GO BACK TO SPIN</a>
      </div>
    </div>
  );
}
