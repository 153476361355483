import React, {
  useRef, useState, Fragment, useEffect,
} from 'react';
import {
  Box,
  Button, Checkbox,
  Chip,
  Dialog,
  Divider, FormControl,
  Grid, IconButton, InputLabel,
  List, ListItem,
  ListItemButton,
  ListItemText, MenuItem,
  Paper, Select,
  Stack,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ArrowDownward,
  ArrowUpward, Clear,
  Delete, FullscreenExit,
  RemoveDone,
  Shuffle,
  Visibility, ZoomIn, ZoomOut,
  Fullscreen, ListAlt, Brush,
} from '@mui/icons-material';
// eslint-disable-next-line import/no-extraneous-dependencies
import localforage from 'localforage';
import { useTranslation } from 'react-i18next';
import SpinGame from '../../components/SpinGame';
import Styles from './styles.module.css';
import ListManager from '../../components/ListManager';
import useListManager from '../../hooks/useListManager';
import spinWheelLogo from '../../spinWheelLogo.png';
import useLang from '../../hooks/useLang';
// const colorsForWheelData2 = ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93'];
const colorsForWheelData = [
  '#3D005E',
  '#A203A6',
  '#DC1D68',
  '#38A6D9',
  '#4BEEEF',
];

function countReferenceInArray(reference, array) {
  return array.reduce((acc, next) => (next === reference ? acc + 1 : acc), 0);
}

export default function SpinningWheel() {
  useLang();
  const { t } = useTranslation();
  const spinRef = useRef(null);
  const stackRef = useRef(null);
  const secondSpinRef = useRef(null);
  const [addInputValue, setAddInputValue] = useState('');
  const [viewAsList, setViewAsList] = useState(true);
  const [sortDirection, setSortDirection] = useState('asc');
  const [winner, setWinner] = useState('');
  const [winners, setWinners] = useState([]);
  const [multipleWinner, setMultipleWinner] = useState(false);
  const [maxTime, setMaxTime] = useState(1);
  const [active, setActive] = useState('default');
  const [data, setData] = useState([]);
  const [dataValue, setDataValue] = useState(data.map((e) => e[0]).join('\n'));
  const [isSpinning, setIsSpinning] = useState(false);
  const [showWinner, setShowWinner] = useState(false);
  const [wheelSizeInPixel, setWheelSizeInPixel] = useState(500);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreenListManager, setIsFullScreenListManager] = useState(false);
  const [showListManager, setShowListManager] = useState(false);
  const [autoWheelSide, setAutoWheelSide] = useState(true);
  const { listNames, onAdd, onDelete } = useListManager(active);

  useEffect(() => {
    localforage.getItem(`spinning-wheel-metas.data.${active}`)
      .then((d) => {
        setData(d.map((e, i) => (e ? [e[0], colorsForWheelData[i % colorsForWheelData.length]] : e)));
        setDataValue(d.map((e) => (e ? e[0] : e)).join('\n'));
      })
      .catch((e) => {
        console.error(e);
        setData([]);
      });
  }, []);

  const setActiveState = (newActive) => {
    localforage.getItem(`spinning-wheel-metas.data.${newActive || 'default'}`)
      .then((d) => {
        setData(d.map((e, i) => (e ? [e[0], colorsForWheelData[i % colorsForWheelData.length]] : e)));
        setDataValue(d.reduce((acc, next) => `${acc}\n${next[0]}`, ''));
      })
      .catch((e) => {
        console.error(e);
        setData([]);
        setDataValue('\n');
      });
    setActive(newActive || 'default');
  };

  const handleRemove = () => {
    const newDataValue = `${dataValue}\n`.replace(`${winner}\n`, '');
    setDataValue(newDataValue);
    const splitValues = newDataValue.split('\n').map((e) => e.trim()).filter((e) => !!e);
    const newData = splitValues
      .map((e, i) => (e ? [e, colorsForWheelData[i % colorsForWheelData.length]] : e))
      .filter((e) => !!e);
    setData(newData);
    localforage
      .setItem(`spinning-wheel-metas.data.${active}`, newData).catch((e) => {
        console.error(e);
      });
    setShowWinner(false);
  };

  const handleRemoveAll = () => {
    const newDataValue = `${dataValue}\n`.replaceAll(`${winner}\n`, '');
    setDataValue(newDataValue);
    const splitValues = newDataValue.split('\n').map((e) => e.trim()).filter((e) => !!e);
    const newData = splitValues
      .map((e, i) => (e ? [e, colorsForWheelData[i % colorsForWheelData.length]] : e))
      .filter((e) => !!e);
    setData(newData);
    localforage
      .setItem(`spinning-wheel-metas.data.${active}`, newData).catch((e) => {
        console.error(e);
      });
    setShowWinner(false);
  };

  const handleShuffle = () => {
    const shuffledArray = data.map((e) => e[0]).sort(() => 0.5 - Math.random());
    const newDataValue = shuffledArray.join('\n');
    setDataValue(newDataValue);
    const newData = shuffledArray.map((e, i) => (e ? [e, colorsForWheelData[i % colorsForWheelData.length]] : e));
    setData(newData);
    localforage
      .setItem(`spinning-wheel-metas.data.${active}`, newData).catch((e) => {
        console.error(e);
      });
  };

  const handleRemoveDuplicate = () => {
    const uniqArray = [...new Set(data.map((e) => e[0]))];
    const newDataValue = uniqArray.join('\n');
    setDataValue(newDataValue);
    const newData = uniqArray.map((e, i) => (e ? [e, colorsForWheelData[i % colorsForWheelData.length]] : e));
    setData(newData);
    localforage
      .setItem(`spinning-wheel-metas.data.${active}`, newData).catch((e) => {
        console.error(e);
      });
  };

  const handleSort = () => {
    const shuffledArray = data.map((e) => e[0]).sort((a, b) => (sortDirection === 'asc' ? 1 : -1) * a.localeCompare(b));
    const newDataValue = shuffledArray.join('\n');
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setDataValue(newDataValue);
    const newData = shuffledArray.map((e, i) => (e ? [e, colorsForWheelData[i % colorsForWheelData.length]] : e));
    setData(newData);
    localforage
      .setItem(`spinning-wheel-metas.data.${active}`, newData).catch((e) => {
        console.error(e);
      });
  };

  const handleVisibility = () => {
    setViewAsList(!viewAsList);
  };

  const handleDeleteEntry = (index) => {
    let newData = data.map((e, i) => (i === index ? null : e[0])).filter((e) => !!e);
    const newDataValue = newData.join('\n');
    setDataValue(newDataValue);
    newData = newData.map((e, i) => (e ? [e, colorsForWheelData[i % colorsForWheelData.length]] : e));
    setData(newData);
    localforage
      .setItem(`spinning-wheel-metas.data.${active}`, newData).catch((e) => {
        console.error(e);
      });
  };

  const handleAddEntry = () => {
    if (addInputValue.trim()) {
      let newData = [...data.map((e) => e[0]), addInputValue.trim()];
      const newDataValue = newData.join('\n');
      setAddInputValue('');
      setDataValue(newDataValue);
      newData = newData.map((e, i) => (e ? [e, colorsForWheelData[i % colorsForWheelData.length]] : e));
      setData(newData);
      localforage
        .setItem(`spinning-wheel-metas.data.${active}`, newData).catch((e) => {
          console.error(e);
        });
    }
  };

  const handleAddEntries = () => {
    let newData = [...data.map((e) => e[0])];
    new Array(10).fill(null).forEach((_, index) => {
      newData.push(`Example ${index + 1}`);
    });
    const newDataValue = newData.join('\n');

    setDataValue(newDataValue);
    newData = newData.map((e, i) => (e ? [e, colorsForWheelData[i % colorsForWheelData.length]] : e));
    setData(newData);
    localforage
      .setItem(`spinning-wheel-metas.data.${active}`, newData).catch((e) => {
        console.error(e);
      });
  };

  const handleZoomOut = () => {
    setWheelSizeInPixel(wheelSizeInPixel - 50);
    setData([...data]);
  };

  const handleZoomIn = () => {
    setWheelSizeInPixel(wheelSizeInPixel + 50);
    setData([...data]);
  };

  const handleAutoWheelSize = async () => {
    await setAutoWheelSide(!autoWheelSide);
    setData([...data]);
  };

  const handleOpenListManager = () => {
    setShowListManager(true);
  };

  const handleFullScreen = () => {
    if (!isSpinning) setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    if (!isSpinning) setIsFullScreen(false);
  };

  const handleFullScreenListManager = () => {
    setIsFullScreenListManager(true);
  };

  const handleExitFullScreenListManager = () => {
    setIsFullScreenListManager(false);
  };

  const handleInputData = (event) => {
    setDataValue(event.target.value);
    // const value = event.target.value.replaceAll(/[^\x20-\x7E]/gmi, "") // comma seperated
    // const splitValues = value.split(',').map(e => e.trim()); // comma seperated
    const splitValues = event.target.value.split('\n').map((e) => e.trim()).filter((e) => !!e);
    const newData = splitValues
      .map((e, i) => (e ? [e, colorsForWheelData[i % colorsForWheelData.length]] : e))
      .filter((e) => !!e);
    setData(newData);
    localforage
      .setItem(`spinning-wheel-metas.data.${active}`, newData).catch((e) => {
        console.error(e);
      });
  };

  const handleClearEntries = () => {
    setWinner('');
    setDataValue('');
    setData([]);
    localforage
      .setItem(`spinning-wheel-metas.data.${active}`, []).catch((e) => {
        console.error(e);
      });
  };

  const handleResetWinner = () => {
    setWinner('');
    setWinners([]);
  };

  const redrawWheel = async () => {
    await setAutoWheelSide(!autoWheelSide);
    await setAutoWheelSide(autoWheelSide);
    setData([...data]);
  };

  const spin = async (ref) => {
    redrawWheel().then(() => {
      setIsSpinning(true);
      const newWinner = data[Math.floor(Math.random() * data.length)][0] || '';
      const newMultipleWinner = countReferenceInArray(newWinner, data.map((e) => e[0]));
      setMultipleWinner(newMultipleWinner > 1);
      setWinner(newWinner);
      ref.current.handleSpin();
      setTimeout(() => {
        setWinners([...winners, newWinner]);
        setShowWinner(true);
        setIsSpinning(false);
      }, maxTime * 1000);
    });
  };

  return (
    <Box id="select-pages-container">
      <Grid container direction="row" justifyContent="center" alignItems="center" paddingTop="1rem">
        <img style={{ width: 614, height: 200 }} alt="logo" src={spinWheelLogo} />
      </Grid>
      <Box className={Styles.container}>
        <Grid direction="row" container spacing="1rem" justifyContent="space-between">
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={9} className={Styles.paper}>
              <Stack spacing="0.5em">
                <Typography variant="h3" align="center">{t('options.main')}</Typography>

                <Grid container direction="row" spacing="0.5rem">
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={<Shuffle />}
                      onClick={handleShuffle}
                      label={t('options.optionTitles.shuffleData')}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={<RemoveDone />}
                      onClick={handleRemoveDuplicate}
                      label={t('options.optionTitles.removeDuplicateData')}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
                      onClick={handleSort}
                      label={t('options.optionTitles.sort')}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={<Clear />}
                      onClick={handleClearEntries}
                      label={t('options.optionTitles.clearEntries')}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      variant="filled"
                      label={`${t('options.optionTitles.entries')} ${data.length}`}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={<Visibility />}
                      onClick={handleVisibility}
                      label={viewAsList ? t('options.optionTitles.viewAsInput') : t('options.optionTitles.viewAsList')}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={<Add />}
                      onClick={handleAddEntries}
                      label={t('options.optionTitles.addExampleEntries')}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={<ZoomOut />}
                      onClick={handleZoomOut}
                      label={t('options.optionTitles.zoomOut')}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={<ZoomIn />}
                      onClick={handleZoomIn}
                      label={t('options.optionTitles.zoomIn')}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={<ListAlt />}
                      onClick={handleOpenListManager}
                      label={t('options.optionTitles.openListManager')}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={<Checkbox checked={autoWheelSide} size="small" />}
                      onClick={handleAutoWheelSize}
                      label={t('options.optionTitles.autoWheelSize')}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={Styles.chip}
                      disabled={isSpinning}
                      variant="filled"
                      icon={<Brush />}
                      onClick={() => redrawWheel()}
                      label={t('options.optionTitles.redrawWheel')}
                      clickable
                    />
                  </Grid>
                </Grid>

                <Box sx={{ paddingY: '1rem' }}>
                  <Divider color="white" />
                </Box>
                <Stack direction="column" spacing="1em">
                  <FormControl className={Styles['form-control']}>
                    <InputLabel>Active List</InputLabel>
                    <Select
                      label="Active List"
                      sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        borderColor: 'white',
                        borderWidth: 0,
                        fontSize: '1.15em',
                      }}
                      variant="outlined"
                      value={active}
                      disabled={isSpinning}
                      onChange={(e) => setActiveState(e.target.value)}
                    >
                      {listNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className={Styles['form-control']}>
                    <TextField
                      id="time-in-seconds-input"
                      label="Time in seconds for the spin"
                      disabled={isSpinning}
                      type="number"
                      onChange={(e) => setMaxTime(e.target.valueAsNumber)}
                      value={maxTime}
                      className={Styles.input}
                    />
                  </FormControl>

                  {viewAsList ? (
                    <Paper className={Styles['list-container']}>
                      <List disablePadding dense className={Styles.list}>
                        {data.length > 0 && data.map(([w], index) => (
                          <Fragment key={`${w}-${index + 1}`}>
                            <ListItem secondaryAction={(
                              <IconButton
                                disabled={isSpinning}
                                onClick={() => handleDeleteEntry(index)}
                                edge="end"
                                aria-label="delete"
                              >
                                <Delete />
                              </IconButton>
                          )}
                            >
                              <Stack spacing="1em" direction="row" sx={{ width: 'inherit' }}>
                                <Typography>
                                  {index + 1}
                                  .
                                </Typography>
                                <Box sx={{ width: '85%' }}>
                                  <Typography noWrap sx={{ textOverflow: 'ellipse' }}>
                                    {w}
                                  </Typography>
                                </Box>
                              </Stack>
                            </ListItem>
                            <Divider />
                          </Fragment>
                        ))}
                        <ListItem
                          secondaryAction={(
                            <IconButton
                              disabled={isSpinning}
                              onClick={() => handleAddEntry()}
                              edge="end"
                              aria-label="add"
                            >
                              <Add />
                            </IconButton>
                        )}
                        >
                          <ListItemText>
                            {data.length + 1}
                            .
                          </ListItemText>
                          <input
                            disabled={isSpinning}
                            className={`${Styles.input} ${Styles['input-no-padding']}`}
                            style={{ width: '90%' }}
                            value={addInputValue}
                            onChange={(e) => setAddInputValue(e.target.value)}
                          />
                        </ListItem>
                      </List>
                    </Paper>
                  ) : (
                    <Tooltip title="Data for the spin wheel, every line is one entry">
                      <TextareaAutosize
                        className={Styles.input}
                        disabled={isSpinning}
                        value={dataValue}
                        minRows={10}
                        maxRows={11}
                        onChange={handleInputData}
                      />
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={9} className={Styles.paper}>
              <Stack
                ref={stackRef}
                spacing="1em"
              >
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                  <Grid item xs={2} sm={12} />
                  <Grid item xs="auto" sm={10}>
                    <Typography variant="h3" align="center">{t('wheel.main')}</Typography>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <IconButton onClick={handleFullScreen} disabled={isSpinning}>
                      <Fullscreen fontSize="large" />
                    </IconButton>
                  </Grid>
                </Grid>

                <Box maxHeight="calc(100vh - 500px)" overflow="hidden">
                  <SpinGame
                    fontSize={`${data.length > 50 ? 12 : 18}px`}
                    data={data}
                    diameterInPixels={autoWheelSide ? stackRef?.current?.offsetWidth : wheelSizeInPixel}
                    time={maxTime}
                    ref={spinRef}
                    hideButton
                    result={winner}
                  />
                </Box>

                <Button
                  className={Styles['button-secondary']}
                  fullWidth
                  variant="contained"
                  onClick={() => spin(spinRef)}
                  disabled={isSpinning || data.length === 0}
                >
                  {t('wheel.spin')}
                </Button>
              </Stack>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Paper elevation={9} className={Styles.paper}>
              <Stack spacing="1em">
                <Typography variant="h3" align="center">{t('winners.main')}</Typography>

                <List dense className={Styles.list}>
                  {winners.length > 0 ? winners.map((w, index) => (
                    <Fragment key={`${w}-${index + 1}`}>
                      <Tooltip title={t('winners.tooltip')}>
                        <ListItemButton onClick={() => navigator.clipboard.writeText(w)}>
                          <Stack spacing="1em" direction="row" sx={{ width: 'inherit' }}>
                            <Typography>
                              {index + 1}
                              .
                            </Typography>
                            <Box sx={{ width: '250px' }}>
                              <Typography noWrap sx={{ textOverflow: 'ellipse' }}>
                                {w}
                              </Typography>
                            </Box>
                          </Stack>
                        </ListItemButton>
                      </Tooltip>
                      <Divider />
                    </Fragment>
                  )) : (
                    <>
                      <ListItemButton>
                        <ListItemText>{t('winners.noWinners')}</ListItemText>
                      </ListItemButton>
                      <Divider />
                    </>
                  )}
                </List>

                {winners.length > 0 && (
                <>
                  <Button
                    className={Styles['button-secondary']}
                    fullWidth
                    variant="contained"
                    onClick={() => navigator.clipboard.writeText(winners.join('\n'))}
                  >
                    {t('winners.copyWinners')}
                  </Button>

                  <Button
                    className={Styles.button}
                    disabled={isSpinning}
                    fullWidth
                    variant="outlined"
                    onClick={handleResetWinner}
                  >
                    {t('winners.resetWinners')}
                  </Button>
                </>
                )}
              </Stack>
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          open={isFullScreen}
          fullScreen
          className={Styles['fullwidth-container']}
        >
          <Stack
            padding="1rem"
            spacing="1em"
            alignItems="center"
            height="100%"
            sx={{ background: 'linear-gradient(45deg, rgba(220, 29, 104, 0.8) 25%, rgba(68, 208, 209, 0.8) 75%)' }}
          >
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Typography variant="h3" align="center">{t('wheel.main')}</Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={handleExitFullScreen} disabled={isSpinning}>
                  <FullscreenExit fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>

            <SpinGame
              id="wheel2"
              fontSize={`${data.length > 50 ? 12 : 18}px`}
              data={data}
              diameterInPixels={
                 window.innerWidth > 500
                   ? window.innerHeight - 220 : window.innerWidth - 100
              }
              time={maxTime}
              ref={secondSpinRef}
              hideButton
              result={winner}
            />

            <Button
              sx={{ maxWidth: '300px' }}
              className={`${Styles['button-secondary']} ${Styles.highlight}`}
              variant="contained"
              onClick={() => spin(secondSpinRef)}
              disabled={isSpinning || data.length === 0}
            >
              {t('wheel.spin')}
            </Button>
          </Stack>
        </Dialog>

        <Dialog
          open={showListManager}
          onClose={() => setShowListManager(false)}
          fullScreen={isFullScreenListManager}
          maxWidth="md"
        >
          <Paper
            className={Styles.dialog}
            sx={{
              backgroundColor: '#333', padding: '2rem', height: '100%', overflowY: 'auto',
            }}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Typography variant="h2" align="center">{t('listManager.main')}</Typography>
              </Grid>
              <Grid item xs={1}>
                {isFullScreenListManager ? (
                  <IconButton onClick={handleExitFullScreenListManager}>
                    <FullscreenExit fontSize="large" />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleFullScreenListManager}>
                    <Fullscreen fontSize="large" />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            <ListManager
              active={active}
              setActive={setActiveState}
              listNames={listNames}
              onAdd={onAdd}
              onDelete={onDelete}
            />
          </Paper>
        </Dialog>

        <Dialog
          open={showWinner}
          onClose={() => setShowWinner(false)}
          maxWidth="lg"
          sx={{ backgroundColor: 'transparent' }}
          PaperProps={{ sx: { backgroundColor: 'rgba(30, 30, 30, 1)' } }}
        >
          <Stack padding="2em" className={Styles.dialog}>
            <Typography variant="h2" align="center">{t('winnerDialog.andTheWinnerIs')}</Typography>
            <Typography variant="h3" align="center">{winner}</Typography>

            <Stack spacing="1rem" direction="row" marginTop="1rem">
              <Button
                className={Styles.button}
                fullWidth
                onClick={() => setShowWinner(false)}
              >
                {t('winnerDialog.close')}
              </Button>
              <Button
                className={Styles.button}
                variant="outlined"
                fullWidth
                onClick={handleRemove}
              >
                {t('winnerDialog.remove')}
              </Button>
              {multipleWinner && (
              <Button
                className={Styles.button}
                variant="outlined"
                fullWidth
                onClick={handleRemoveAll}
              >
                {t('winnerDialog.removeAll')}
              </Button>
              )}
            </Stack>
          </Stack>
        </Dialog>
      </Box>
    </Box>
  );
}
