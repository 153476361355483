import React from 'react';
import ReactDOM from 'react-dom/client';
import localforage from 'localforage';
import App from './app';

// create a localStorage like store to be used to store bigger cache
// DOCS: https://www.npmjs.com/package/localforage
window.localForage = localforage.createInstance({ name: 'JDL Spinner Wheel' });

// TODO add team making capability + dropdown for different list

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
