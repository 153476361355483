/* eslint-disable */
import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Styles from './styles.module.css';

const SpinAndWin = forwardRef(
  (
    {
      id,
      data,
      hideButton,
      result,
      time,
      minTime,
      maxTime,
      removeButtonEffect,
      fontSize,
      fontFamily,
      horizontalText,
      diameterInPixels,
    },
    ref,
  ) => {
    const wheelRef = useRef();
    const [state] = useState({ winnerAngle: 0 });

    useEffect(() => {
      const wheelCanvas = document.getElementById(id || 'wheel');
      if (wheelCanvas && isCanvas(wheelCanvas)) {
        const wheel = wheelCanvas.getContext('2d');
        const wheelX = wheelCanvas.width / 2;
        const wheelY = wheelCanvas.height / 2;
        const wheelRadius = Math.min(wheelX, wheelY);

        drawWheel(data, wheel, wheelX, wheelY, wheelRadius);
      }
    }, [data]);

    function isCanvas(obj) {
      return obj.tagName === 'CANVAS';
    }

    const degToRad = (deg) => (deg * Math.PI) / 180.0;

    const drawWheel = (
      list,
      wheel,
      wheelX,
      wheelY,
      wheelRadius,
    ) => {
      const segment = 360 / list.length;

      list.forEach((el, i) => {
        wheel.save();
        wheel.translate(wheelX, wheelY);
        wheel.rotate(degToRad(segment * i));
        wheel.translate(-wheelX, -wheelY);

        wheel.fillStyle = el[1] ? el[1] : 'red';

        wheel.beginPath();
        wheel.moveTo(wheelX, wheelY);
        wheel.arc(
          wheelX,
          wheelY,
          wheelRadius,
          0 - degToRad(90) - degToRad(segment / 2),
          degToRad(segment) - degToRad(90) - degToRad(segment / 2),
          false,
        );
        wheel.moveTo(wheelX, wheelY);
        wheel.fill();

        wheel.fillStyle = 'white';
        horizontalText ? (wheel.textAlign = 'start') : (wheel.textAlign = 'end');
        wheel.font = fontSize && fontFamily
          ? `${fontSize}px ${fontFamily}`
          : fontSize
            ? `${fontSize}px sans-serif`
            : fontFamily
              ? `18px ${fontFamily}`
              : '18px sans-serif';
        wheel.transform = 'translate(50px, 100px)';
        if (horizontalText) {
          wheel.textAlign = 'center';
          wheel.fillText(el[0], wheelX, wheelY / 4);
        } else {
          wheel.rotate(-1.57);
          wheel.fillText(el[0], -15, wheelY + 5);
        }

        wheel.restore();
      });
    };

    useImperativeHandle(ref, () => ({
      handleSpin() {
        const wheelCanvas = document.getElementById(id || 'wheel');
        if (wheelCanvas) {
          const freeSpinResult = result || '23454';
          const transitionTime = time || (minTime && maxTime && minTime > 0 && maxTime > 0
            ? Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime
            : Math.floor(Math.random() * (4 - 3 + 1)) + 3);
          wheelCanvas.style.transition = `${transitionTime}s`;

          let winner = data.find((item) => item[0] === freeSpinResult);
          if (!winner) {
            winner = [''];
          }
          const freeSpinGifts = [];
          data.forEach((item) => {
            freeSpinGifts.push(item[0]);
          });
          const winnerIndex = freeSpinGifts.indexOf(winner[0]);
          const offset = state.winnerAngle % 360;
          state.winnerAngle = state.winnerAngle
            + 1800
            - (360 * winnerIndex) / freeSpinGifts.length
            - offset;
          const deg = `rotate(${state.winnerAngle}deg)`;
          wheelCanvas.style.transform = deg;
        }
      },
    }));

    const handleSpin = () => {
      const wheelCanvas = document.getElementById(id || 'wheel');
      if (wheelCanvas) {
        const freeSpinResult = result || '23454';
        const transitionTime = time || (minTime && maxTime && minTime > 0 && maxTime > 0
          ? Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime
          : Math.floor(Math.random() * (4 - 3 + 1)) + 3);
        wheelCanvas.style.transition = `${transitionTime}s`;

        let winner = data.find((item) => item[0] === freeSpinResult);
        if (!winner) {
          winner = [''];
        }
        const freeSpinGifts = [];
        data.forEach((item) => {
          freeSpinGifts.push(item[0]);
        });
        const winnerIndex = freeSpinGifts.indexOf(winner[0]);
        const offset = state.winnerAngle % 360;
        state.winnerAngle = state.winnerAngle
          + 2520
          - (360 * winnerIndex) / freeSpinGifts.length
          - offset;
        wheelCanvas.style.transform = `rotate(${state.winnerAngle}deg)`;
      }
    };

    return (
      <div>
        <div className={Styles['rewards-spin-game']}>
          <div className={Styles['canvas-container']} style={{ minWidth: diameterInPixels || 450, minHeight: diameterInPixels || 450 }}>
            <canvas
              ref={wheelRef}
              id={id || 'wheel'}
              className={`${Styles.wheel} wheel`}
              width={diameterInPixels ? `${diameterInPixels - 49}px` : '400px'}
              height={diameterInPixels ? `${diameterInPixels - 49}px` : '400px'}
            />
            {!hideButton && (
              <span
                className={Styles.spin}
                onClick={() => (removeButtonEffect ? '' : handleSpin())}
              >
                SPIN
              </span>
            )}
          </div>
          <span className={Styles.arrow}>
            <ArrowDropDownIcon fontSize="large" />
          </span>
        </div>
      </div>
    );
  },
);

export default SpinAndWin;
