import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Collapse, createTheme,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField, ThemeProvider,
  Typography,
} from '@mui/material';
import {
  Add, Delete, KeyboardArrowDown, KeyboardArrowUp, MoreHoriz,
} from '@mui/icons-material';
// eslint-disable-next-line import/no-extraneous-dependencies
import localforage from 'localforage';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import Styles from './styles.module.css';

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: 'white',
          },
        },
      },
    },
  },
});

function ListRow({
  name,
  active,
  setActive,
  onDelete,
}) {
  const anchorEl = useRef(null);
  const [data, setData] = useState([]);
  const [moreOpen, setMoreOpen] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const disabledDelete = active === name || name === 'default';

  useEffect(() => {
    localforage
      .getItem(`spinning-wheel-metas.data.${name}`)
      .then((l) => setData(l ? l.map((e) => e[0]) : []))
      .catch((err) => {
        console.error(err);
        setData([]);
      });
  }, []);

  const toggleList = () => {
    setListOpen(!listOpen);
  };

  return (
    <>
      <TableRow key={name}>
        <TableCell component="th" scope="row">
          <Checkbox onClick={() => setActive(name)} checked={active === name} />
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell align="right">
          <IconButton
            onClick={toggleList}
          >
            {listOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          <IconButton
            ref={anchorEl}
            onClick={() => setMoreOpen(true)}
          >
            <MoreHoriz />
          </IconButton>
          <Popover
            anchorEl={anchorEl.current}
            open={moreOpen}
            onClose={() => setMoreOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <List>
              <ListItem>
                <ListItemButton disabled={disabledDelete} onClick={() => onDelete(name)}>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <ListItemText>
                    Delete
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Popover>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={listOpen} timeout="auto" unmountOnExit>
            <List disablePadding dense className={Styles.list}>
              {data.length > 0 && data.map((w, index) => (
                <Fragment key={`${w}-${index + 1}`}>
                  <ListItem>
                    <Stack spacing="1em" direction="row" sx={{ width: 'inherit' }}>
                      <Typography>
                        {index + 1}
                        .
                      </Typography>
                      <Box sx={{ width: '85%' }}>
                        <Typography noWrap sx={{ textOverflow: 'ellipse' }}>
                          {w}
                        </Typography>
                      </Box>
                    </Stack>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

ListRow.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function ListManager({
  active,
  setActive,
  listNames,
  onAdd,
  onDelete,
}) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('lists-view');

  useEffect(() => {
    if (listNames.includes(inputValue.trim())) setError('Cannot have duplicate list names');
    if (!inputValue.trim()) setError('Cannot have blank name');
    if (!listNames.includes(inputValue.trim()) && !!inputValue.trim()) setError('');
  }, [listNames]);

  const handleAdd = () => {
    if (!listNames.includes(inputValue.trim()) || !!inputValue.trim()) {
      onAdd(inputValue.trim());
      setInputValue('');
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);

    if (listNames.includes(e.target.value.trim())) setError('Cannot have duplicate list names');
    if (!e.target.value.trim()) setError('Cannot have blank name');
    if (!listNames.includes(e.target.value.trim()) && !!e.target.value.trim()) setError('');
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper sx={{
        backgroundColor: 'rgba(220, 220, 220, 0.1)',
        maxHeight: '80%',
        overflowY: 'auto',
        margin: '2rem',
      }}
      >
        <TabContext value={activeTab}>
          <TabList onChange={(e, v) => setActiveTab(v)}>
            <Tab label={t('listManager.tabs.listView')} value="lists-view" />
            <Tab label={t('listManager.tabs.listMerger')} value="lists-merger" />
          </TabList>
          <TabPanel value="lists-view">
            <Table
              className={Styles.table}
              sx={{ minWidth: '200px', padding: '1rem' }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t('listManager.listView.active')}</TableCell>
                  <TableCell>{t('listManager.listView.listName')}</TableCell>
                  <TableCell align="right">{t('listManager.listView.action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Checkbox disabled />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={`${Styles['input-no-padding']} ${Styles.input}`}
                      fullWidth
                      error={!!error}
                      helperText={error}
                      onChange={handleChange}
                      value={inputValue}
                      onKeyDown={(e) => (e.keyCode === 13 && !error ? handleAdd() : null)}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      disabled={!!error}
                      onClick={handleAdd}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                {listNames.map((name) => (
                  <ListRow
                    key={name}
                    active={active}
                    setActive={setActive}
                    onDelete={onDelete}
                    name={name}
                  />
                ))}
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value="lists-merger">{t('listManager.listMerger.main')}</TabPanel>
        </TabContext>
      </Paper>
    </ThemeProvider>
  );
}

ListManager.propTypes = {
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  listNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ListManager;
