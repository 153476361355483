import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import SpinningWheel from './page/home';
import ErrorPage from './error-page';
import enTranslation from './i18n/en.json';
import frTranslation from './i18n/fr.json';

console.log(enTranslation);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    resources: {
      en: enTranslation,
      fr: frTranslation,
    },
    lng: 'en',
    defaultNS: 'default',
  });
i18n.languages = ['en', 'fr'];

const router = createBrowserRouter([
  {
    path: '/:lang/',
    element: <SpinningWheel />,
    errorElement: <ErrorPage />,
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
]);

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} />
    </I18nextProvider>
  );
}

export default App;
