import { useEffect, useState } from 'react';
import localforage, { setItem } from 'localforage';

function useListManager(active) {
  const [listNames, setListNames] = useState([]);

  useEffect(() => {
    localforage.getItem('spinner-list-names')
      .then((value) => setListNames(value || [active]))
      .catch((err) => {
        console.error(err);
        setListNames([active]);
        return setItem('spinner-list-names', [active]);
      }).catch((err) => console.error(err));
  }, []);

  const onAdd = (newListName) => {
    localforage
      .setItem('spinner-list-names', [...listNames, newListName])
      .then(() => localforage.getItem('spinner-list-names'))
      .then((value) => setListNames(value)).catch((err) => {
        console.error(err);
        setListNames(listNames);
      });
  };

  const onDelete = (name) => {
    localforage
      .setItem('spinner-list-names', [...listNames.filter((e) => e.trim() !== name.trim())])
      .then(() => localforage.getItem('spinner-list-names'))
      .then((value) => setListNames(value)).catch((err) => {
        console.error(err);
        setListNames(listNames);
      });
  };

  return { listNames, onAdd, onDelete };
}

export default useListManager;
